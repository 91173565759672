@import '../theme-bootstrap';

// Klarna overlay initiation.
.klarna-overlay {
  .klarna-information {
    font-family: $ano_regular-font;
    padding: 20px;
    &__header {
      background-image: url('#{$base-theme-path}svg-icons/src/icon--klarna.svg');
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: 65px;
      margin-bottom: 25px;
      text-indent: -999em;
      width: 120px;
    }
    &__list {
      counter-reset: klarna-counter;
      margin-bottom: 25px;
      margin-#{$ldirection}: 30px;
    }
    &__list-item {
      align-items: baseline;
      counter-increment: klarna-counter;
      display: flex;
      margin-bottom: 10px;
      &::before {
        content: counter(klarna-counter) '.';
        flex-basis: 20px;
        font-family: $ano-bold_regular-font;
        font-size: 24px;
        padding-#{$rdirection}: 10px;
      }
    }
    &__sub-text {
      color: $color--gray--darker;
      margin: 0;
      .klarna-information__link {
        border: 0;
        border-radius: 0;
        font-family: $ano-bold_regular-font;
        font-size: inherit;
        height: auto;
        line-height: inherit;
        padding: 0;
        text-decoration: underline;
        text-transform: none;
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
  .social-links {
    display: flex;
    gap: 20px;
    margin: 60px 0 15px;
  }
  .page-product & {
    .social-links {
      gap: 0;
      margin: 0;
    }
  }
}
